import React, {useState} from 'react'
import { useForm } from "react-hook-form";
import { AppWrap , MotionWrap } from '../../wrapper'

import { motion } from 'framer-motion';
import { client } from '../../client';

import './Contact.scss'

function Contact() {
  const { register, formState: { errors }, handleSubmit } = useForm();
  const onSubmit = (data) =>{
    setLoading(true);
    const contact = {
      _type: 'contact',
      name: data.username,
      mobile: data.mobile,
      message: data.message,
    };

    
    client.create(contact)
    .then(() => {
      setLoading(false);
      setIsFormSubmitted(true);
    })
    .catch((err) => console.log(err));
  } ;


  const [formData, setFormData] = useState({ name: '', mobile: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, mobile, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  return (
    <>
        <h2 className='head-text font-isans font-25'>ارتباط با من</h2>
        <p className='p-text font-isans'>
        خیلی خوشحالم که میخوای باهام در ارتباط باشی. اگه مهارت ها و تخصص هام رو خوندی و دوست داشتی که برات پروژه‌ای انجام بدم از طریق فرم زیر بهم پیام بده 
        </p>


        {!isFormSubmitted ? (
       <motion.div
       whileInView={ {y:[100,0], opacity : [0,1]} }
       transition={{duration : 0.8}}
       className="contact-from"
>
     <form className='contact-from ' onSubmit={handleSubmit(onSubmit)}>

     <div className="form-control  font-isans">
         <label htmlFor="username">نام و نام خانوادگی : </label>
         <input type="text" 
          {...register("username", { required: true })} 
          aria-invalid={errors.username ? "true" : "false"} 
         name="username" value={username} 
         id='username' placeholder='نام و نام خانوادگی خود را بنویسید' />
          {errors.username?.type === 'required' && <p className='danger' role="alert">نام و نام خانوادگی الزامی است</p>}
       </div>

       <div className="form-control font-isans">
         <label htmlFor="mobile">شماره تماس: </label>
         <input 
         name="mobile" 
         {...register("mobile", { required: true , pattern: /^09(1[0-9]|3[1-9]|2[1-9])-?[0-9]{3}-?[0-9]{4}/i  })} 
         aria-invalid={errors.mobile ? "true" : "false"} 
         value={mobile} onChange={handleChangeInput}
         type="text" 
         id='mobile' 
         placeholder='شماره تماس خود را وارد نمایید' />
         {errors.mobile?.type === 'required' && <p className='danger' role="alert">{errors.mobile?.message}</p>}
       </div>

       <div className="form-control font-isans w-full">
         <label htmlFor="message">متن پیام : </label>
         <textarea 
         value={message}
         {...register("message", { required: true, maxLength: 20 })}
         name="message"
         onChange={handleChangeInput}
         id='message' 
         cols="20" 
         rows="7" 
         placeholder='متن پیام خود را بنویسید'></textarea>
       </div>

       <div>
       <button type='submit'  className='font-isans btn'>{!loading ? 'ارسال پیام' : 'درحال ارسال...'}</button>
       </div>



     </form>
</motion.div>
      ) : (
        <div>
          <h3 className="head-text font-isans" style={{ direction : 'rtl' }}>
          👋 پیامتو دریافت کردم بزدوی باهات تماس میگیرم.
          </h3>
        </div>
      )}
        
    </>
  )
}

export default AppWrap(MotionWrap(Contact , 'contact') , 'contact' , 'app__graybg')