import React from 'react'
import {motion} from 'framer-motion'
import images from '../../constants'
import {AppWrap} from '../../wrapper'; 
import './Header.scss'

const scaleVariants = {
  whileInView : {
    scale : [0,1],
    opacity : [0,1],
    transition: {
      duration : 1,
      ease : 'easeInOut'
    }
  }
}


function Header() {
  return (
    <div className='app__header app__flex'>
  <motion.div
      variants={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className="app__header-circles"
      >
        {[images.vue , images.react,images.redux].map((circle , index) => (
          <div className='circle-cmp app__flex' key={index}>
            <img src={circle} alt="circle" />
          </div>
        ))}
      </motion.div>

      <motion.div
      whileInView={ { opacity : [0,1]} }
      transition={{ duration:0.5 , delayChildren: 0.5 }}
      className="app__header-img"
      >
        <img src={images.rahim} alt="rahim pangh" />
        <motion.img
            whileInView={ { scale : [0,1]} }
            transition={{ duration:1 , ease:'easeInOut' }}
            src={images.circle}
            alt="profile_cir"
            className="overlay_circle"
        />

      </motion.div>
      

      <motion.div
      whileInView={ {y:[100,0], opacity : [0,1]} }
      transition={{ duration:0.5 }}
      className="app__header-info"
      >
        <div className='app__header-badge'>
          <div className='badge-cmp app__flex'>
            
            <div style={{marginLeft : 20}}>
              <p className='p-text font-isans t-right'>سلام، من</p>
              <h1 className='head-text font-isans'><span>رحیم پنق</span></h1>
              <p className='p-text font-isans t-right'>هستم</p>
            </div>
            <span>👋</span>
          </div>
    
        <div className='tag-cmp app__flex'>
        <p className='p-text font-isans'>برنامه نویس</p>
        <p className='p-text font-isans'>فریلنسر</p>

        </div>


        </div>
      </motion.div>
     

    </div>
  )
}

export default AppWrap(Header , 'home')