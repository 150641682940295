import React from 'react'
import { BsInstagram} from 'react-icons/bs'
import { AiFillLinkedin } from 'react-icons/ai'
function SocialMedia() {
  return (
    <div className='app__social'>
      
        <div>
            <a href="https://instagram.com/rahim.pgh" target="_blank"><BsInstagram /></a>
        </div>
        <div>
            <a href="https://linkedin.com/in/rahim-pangh" target="_blank"><AiFillLinkedin /></a>
        </div>
    </div>
  )
}

export default SocialMedia