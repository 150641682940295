import React , {useState , useEffect} from 'react'
import {AppWrap , MotionWrap} from '../../wrapper'
import { motion } from 'framer-motion'
import {urlFor , client } from '../../client'
import ReactTooltip from 'react-tooltip'
import './Skills.scss'

function Skills() {

  const [expriences, setExpriences] = useState([])
  const [skills, setSkills] = useState([])

  
useEffect(() => {
  const query = '*[_type == "experiences" ]';
  const skillQuery = '*[_type == "skills" ]';

  client.fetch(query)
  .then((data) => {
    const numAscending = [...data].sort((a, b) => a.year - b.year);
        setExpriences(numAscending);
      })

      client.fetch(skillQuery)
      .then((data) => {
            setSkills(data);
          })


  }, []);
 

      



  return (
    <>
    <h2 className='head-text font-isans'>مهارت ها & <span>تجربه</span> کاری</h2>

    <div className='app__skills-container'>
      <motion.div
      className='app__skills-list'
      >
        {skills.map((skill , index) => (
          <motion.div
          whileInView={{opacity : [0,1]}}
          transition={{duration:0.5}}
          className="app__skills-item app__flex"
          key={index}
          >
            <div className='app__flex' style={{backgroundColor : skill.bgColor }}>
              <img width="45" height="45" src={urlFor(skill.icon)} alt={skill.name} />
            </div>
            <p className='p-text'>{skill.name}</p>
          </motion.div>
        ))}
      </motion.div>

      <motion.div
      className='app__skills-exp'
      >
        {expriences.map((exprience , lo) => (
         
         <motion.div
          className='app__skills-exp-item'
          key={lo}
         >
          <div className="app__skills-exp-year">
              <p className="bold-text">{exprience.year}</p>
          </div>
          <motion.div className='app__skills-exp-works'>
            {
              exprience.works.map((work , i) => (
              
               <div key={i}>
                   <motion.div
                whileInView={{opacity : [0,1]}}
                transition={{duration:0.5}}
                className="app__skills-exp-work"
                data-tip
                data-for={work.name}
                
                
                >
      
              <h4 className='bold-text font-isans'>{work.name}</h4>
              <p className='p-text font-isansnpm  font-isans start'>{work.company}</p>
                </motion.div>
                <ReactTooltip
                id={work.name}
                effect="solid"
                arrowColor='#fff'
                className='skills-tooltip font-isans'
                >
                  {work.desc}
                </ReactTooltip>
               </div>
               
          
                
              ))
            }
          </motion.div>
         </motion.div>
        ))}
      </motion.div>
    </div>
    </>
  )
}


export default AppWrap(MotionWrap(Skills , 'app__skills') , 'skills' , 'app__whitebg')