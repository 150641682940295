import React from 'react'
import './Footer.scss'

function Footer() {
  return (
    <>
      <footer>
      </footer>
    </>
  )
}

export default Footer