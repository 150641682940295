import React , { useState , useEffect} from 'react'
import { motion } from 'framer-motion'
import { urlFor , client } from '../../client'
import {AppWrap , MotionWrap} from '../../wrapper'; 
import './About.scss'



function About() {
  
  const [abouts,setAbouts] = useState([]);

  useEffect( () => {
    const query = '*[_type == "abouts"]'
    client.fetch(query)
      .then((data) => setAbouts(data))

  },[])

  return (
    <>
    <h2 className='head-text font-isans font-25'>
معرفی و توضیحات در مورد خودم 
    </h2>
    <p className=' font-isans about-me'>
    سلام، من رحیم پنق هستم. متولد 24 مهر 1376 و گرگان زندگی میکنم.از اونجایی که از کودکی عاشق کامپیوتر بودم تو رشته مهندسی نرم افزار از دانشکده امام علی(ع) کردکوی فارغ التحصیل شدم. علاقه خودم برنامه نویسی تحت وب هست و همیشه سعی کردم در حیطه کاری، خودم رو بروز نگه دارم.3 سالی میشه بصورت حرفه ای برنامه نویسی رو دنبال میکنم و این مدت سعیم بر این بوده از دیروز خودم بهتر باشم. 
همچنین میتونید حیطه کاری بنده رو مشاهده بفرمایید.
    </p>

    <div className='app__profiles'>
      {abouts.map((about,index) => (
        <motion.div
        whileInView={ {opacity : 1} }
        whileHover={{ scale : 1.1 }}
        transition={{duration : 0.5 , type: 'tween'}}
        className="app__profile-item"
        key={about.title+index}
        >
          <img src={urlFor(about.imgUrl)} width="190" height="170" alt={about.title} />
          <h2 className='bold-text font-isans title' style={{marginTop:20}}>{about.title}</h2>
          <p className='p-text font-isans p-description' style={{marginTop:10}}>{about.description}</p>

        </motion.div>
      ))}
    </div>
    
    </>
  )
}

export default AppWrap(MotionWrap(About , 'app__about') , 'about' , 'app__whitebg')